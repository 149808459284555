import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../helpers/constants';
import SearchBox from '../../components/SearchBox';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { SwiperButtonNext } from '../../components/SwiperButtonNext';
import { SwiperButtonPrev } from '../../components/SwiperButtonPrev';
import APIService from '../../services/services';
import { Helmet } from 'react-helmet';
import { HeaderText } from '../../components/HeaderText';
import { BallonIcon, BriefCaseIcon, GTCSIMPLEICON, MAPMARKERICON, SunIcon } from '../../helpers/svgIcons';
import ScrollToTopButton from '../../components/ScrollToTopButton';
import { StoryItemViewModel } from '../../helpers/types';

const locations = ['Beverly Hills', 'New York', 'London'];
const secondSectionData = [
  {
    icon: <SunIcon />,
    title: 'leisure',
    desc: 'Elevate your travel, whether it be Family Time, Wellness, Weddings & Honeymoons, Cruise or Adventure, we have bespoke solutions to suit your needs.'
  },
  {
    icon: <BriefCaseIcon />,
    title: 'business travel',
    desc: 'Our dedication to excellence in Business Travel, Groups,  Events and Incentive Travel, is demonstrated in our dedicated teams of travel experts offering seamless support. '
  },
  {
    icon: <BallonIcon />,
    title: 'entertainment',
    desc: 'We are immensely proud to serve a distinguished clientele including some of the most renowned names and companies in the industry, looking after Music, Touring, Fashion. '
  }
];

function Home() {
  const [stories, setStories] = useState<StoryItemViewModel[]>([]);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [video, setVideo] = useState<string | null>(null);
  const getStories = () => {
    APIService()
      .gtcService.fetchStories()
      .then((res) => {
        if (res.responseStatus === 1) {
          setStories(res.data);
        } else {
          setStories([]);
        }
      });
  };

  useEffect(() => {
    getStories();
    Modal.setAppElement('#root');
    if (!modalIsOpen) setVideo(null);
  }, [modalIsOpen]);

  return (
    <>
      <Helmet>
        <meta name="title" content="International Luxury Travel Agents | Global Travel Collection" />
        <meta
          name="description"
          content="Our 1,700 GTC advisors and agencies are leaders in providing premium travel services to leisure travelers, corporate executives and the entertainment industry."
        />
        <title>International Luxury Travel Agents | Global Travel Collection</title>
      </Helmet>
      <div className="mx-auto relative overflow-hidden -mt-24 mb-2 md:mb-28">
        <section className="relative h-[470px] md:h-[650px]  flex flex-col items-center justify-center text-center text-white pt-5 sm:py-0 px-3">
          <div className="video-docker absolute top-0 left-0 w-full h-full overflow-hidden">
            <video
              className="min-w-full min-h-full absolute object-cover object-top"
              src="/videos/home-hero-loop.mp4"
              autoPlay
              muted
              playsInline
              loop></video>
          </div>
          <div className="video-content text-center">
            <HeaderText title={'WHEREVER YOU’RE GOING, WE’VE BEEN THERE.'} />
          </div>
          {/* <section className="w-full absolute bottom-0 text-left text-black">
            <SearchBox />
          </section> */}
        </section>
        <div className="px-4 md:px-20 md:pt-32 pt-8 mx-auto flex flex-col gap-4 md:gap-20  2xl:container">
          <section className="flex md:flex-row flex-col-reverse gap-4 md:gap-20 items-center -mx-4 md:mx-0">
            <img src="/images/home/1.png" className="w-[470px] h-[455px] object-cover " />
            <div className="flex flex-col gap-4 md:max-w-3xl items-center md:items-start px-4 md:px-0">
              <h2 className="uppercase text-[26px] md:text-4xl text-center">A COLLECTION OF TRAVEL CURATORS</h2>
              <h4 className="text-gtc1 text-center md:text-left">
                We are the world’s largest collection of international luxury travel agencies. Approximately 1,700 GTC advisors and agencies
                are industry leaders in providing premium travel services to leisure travelers, corporate executives and the entertainment
                industry. The combined global reach and leverage translates into value, recognition, and preferential treatment for its
                world travelers. Wherever you want to go, however you want to get there, whatever you want to do—we have been there.
              </h4>
              <a
                href="/find-an-elite-travel-agent"
                className="w-full py-2 font-normal text-center 
                text-white bg-black border border-black sm:w-64 hover:font-semibold  text-2xl
                px-2 hover:cursor-pointer">
                FIND AN ADVISOR
              </a>
            </div>
          </section>

          <section className="flex md:flex-row flex-col-reverse gap-4 md:gap-20 items-center">
            <div className="flex flex-col gap-8">
              {secondSectionData.map((x) => (
                <div className="flex flex-col items-center md:items-start md:flex-row gap-8" key={x.title}>
                  <div>{x.icon}</div>
                  <div className="flex flex-col gap-1 items-center md:items-start">
                    <div className="uppercase text-2xl font-medium">{x.title}</div>
                    <div className="text-gtc1 text-xl text-center md:text-left">{x.desc}</div>
                  </div>
                </div>
              ))}
              <a
                href="/find-an-elite-travel-agent"
                className="w-full py-2 font-normal text-center 
                text-white bg-black border border-black sm:w-64 hover:font-semibold  text-2xl
                px-2 hover:cursor-pointer">
                FIND AN ADVISOR
              </a>
            </div>
            <img src="/images/home/2.png" className="w-[328px] h-[449px] object-cover " />
          </section>

          <section className="-mx-10 md:-mx-5 overflow-hidden">
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              loop={true}
              pagination={true}
              modules={[Pagination]}
              breakpoints={{
                640: {
                  pagination: false
                }
              }}>
              <div className="absolute inset-y-0 left-4 z-10 md:flex items-center hidden">
                <SwiperButtonPrev className="" />
              </div>
              {stories.map((story) => (
                <SwiperSlide key={story.title}>
                  <div className="swiper-slide px-5">
                    <div className="w-full">
                      <a title={story.title} href={story.url} className="cursor-pointer">
                        <img className="w-full h-[450px] object-cover" src={story.heroImage} alt={story.title} title={story.title} />
                      </a>
                    </div>
                    <div className=" bg-black min-h-[77px] py-2 md:py-4 flex items-center px-4">
                      <p
                        className="m-0 text-[26px]  md:text-4xl text-white uppercase font-normal
                       font-ivypresto-display
                      px-2">
                        {story.title}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}

              <div className="absolute inset-y-0 right-4 z-10 md:flex hidden items-center">
                <SwiperButtonNext className="" />
              </div>
            </Swiper>
          </section>

          <section className="flex flex-col gap-4 md:gap-20 items-center md:mt-0 mt-8">
            <a href="/as-seen-in-media" className="text-xl md:text-4xl">
              AS SEEN IN THE MEDIA
            </a>
            <div className="flex flex-col gap-8 md:gap-10 items-center">
              <div className="grid grid-cols-2 md:flex md:flex-row gap-8 md:gap-10">
                <a href="/as-seen-in-media">
                  <img src="/images/home/media/nbc.png" className="p-6 shadow-xl" />
                </a>
                <a href="/as-seen-in-media">
                  <img src="/images/home/media/extra.png" className="p-6 shadow-xl" />
                </a>
                <a href="/as-seen-in-media">
                  <img src="/images/home/media/cnn.png" className="p-6 shadow-xl" />
                </a>
                <a href="/as-seen-in-media">
                  <img src="/images/home/media/ap.png" className="p-6 shadow-xl py-8" />
                </a>
              </div>
              <div className="grid grid-cols-2 md:flex md:flex-row gap-8 md:gap-10 ">
                <a href="/as-seen-in-media">
                  <img src="/images/home/media/nypost.png" className="p-6 shadow-xl" />
                </a>
                <a href="/as-seen-in-media">
                  <img src="/images/home/media/wsj.png" className="p-6 shadow-xl" />
                </a>
                <a href="/as-seen-in-media" className="col-span-2 mx-auto">
                  <img src="/images/home/media/today.png" className="p-6 shadow-xl" />
                </a>
              </div>
            </div>
          </section>

          <section className="bg-black  text-white p-5 md:p-10 flex md:flex-row flex-col-reverse gap-6 md:gap-10 -mx-4 md:mx-0 overflow-hidden">
            <div className="flex flex-col items-center md:items-start gap-8 flex-1">
              <GTCSIMPLEICON />
              <div className="uppercase text-[26px] md:text-4xl text-center">Who is Global Travel Collection?</div>
              <div className="text-center  text-gtc3 md:text-left max-w-2xl text-xl md:text-2xl">
                Global Travel Collection (GTC) represents the largest and most sophisticated community of premier travel agencies worldwide,
                serving leisure, corporate, incentive, and entertainment travel needs.
              </div>
              <div className="flex flex-col items-center md:items-start md:flex-row gap-8 md:gap-4">
                <button
                  onClick={() => {
                    setVideo('videos/header-modal-desktop.mp4');
                    setIsOpen(true);
                  }}
                  className="default-btn border-white border">
                  View Video
                </button>
                <a href="/about-gtc" className="default-btn border-white border">
                  View Our Family Of Brands
                </a>
              </div>
            </div>
            <div className="relative">
              <img src="/images/home/gtc-loc.png" className="ml-auto w-[286px] md:w-full" />
              <div className="flex flex-col gap-5 absolute bottom-14 md:bottom-10 md:-left-14 left-0">
                {locations.map((x) => (
                  <div
                    className="flex flex-row gap-1 md:gap-2  px-6 md:px-6 py-2 md:py-3 bg-white text-black text-lg md:text-2xl items-center"
                    key={x}>
                    <div className="scale-75 md:scale-100">
                      <MAPMARKERICON />
                    </div>
                    <div>{x}</div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="mb-10">
            <SearchBox />
          </section>
        </div>
        <ScrollToTopButton />
      </div>

      <Modal isOpen={modalIsOpen && video !== null} style={customStyles}>
        <div className="modalVideo">
          <button onClick={() => setIsOpen(false)} className="close-modal text-center cursor-pointer">
            ×
          </button>
          <video
            id="modalVideo"
            autoPlay
            controls
            poster="/images/hero/video-full-poster.jpg"
            onEnded={() => setIsOpen(false)}
            src={video || ''}></video>
        </div>
      </Modal>
    </>
  );
}

export default Home;
